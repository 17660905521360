import http from '@/libs/http'
const url = process.env.VUE_APP_MICROSERVICE_API_CONTRATO_PRECIO

export default class SolicitudContratoService {
  paginarSolicitudes (params) {
    return http.get(`${url}/solicitudes/paginate`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  obtenerClientes () {
    return http.get(`${url}/solcitudes/clientes`)
  }

  buscarContratos (params) {
    return http.get(`${url}/solicitudes/buscar-contratos`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  buscarMedicamentos (params) {
    return http.get(`${url}/solicitudes/buscar-medicamentos`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  crearSolicitud (payload) {
    return http.post(`${url}/solicitudes`, payload, {
      headers: {
        loading: true
      }
    })
  }

  buscarDetalle (id) {
    return http.get(`${url}/solicitudes/obtener-detalle-solicitud/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  responderSolicitud (payload) {
    return http.put(`${url}/solicitudes/responder-solicitud`, payload, {
      headers: {
        loading: true
      }
    })
  }

  validarSolicitidContratoPrecio (params) {
    return http.get(`${url}/solicitudes/validarMxListaPrecio`, {
      params,
      headers: {
        loading: true
      }
    })
  }
}
