import { defineStore } from 'pinia'
import SolicitudContratoService from '../services/solicitud-contrato.service'
const _SolicitudContratoService = new SolicitudContratoService()

export const useSolicitudContratosStore = defineStore('solicitudContratosStore', {
  state: () => ({
    paginado_solicitudes: {
      count: 0,
      rows: {}
    },
    listado_clientes: [],
    listado_articulos: [],
    listado_motivos_autorizacion: [],
    listado_contratos: [],
    solicitud: {
      ContratoId: 0,
      Articulos: []
    },
    solicitud_detalle: []
  }),
  getters: {
    _paginado_solicitudes: state => state.paginado_solicitudes,
    _listado_clientes: state => state.listado_clientes,
    _listado_articulos: state => state.listado_articulos,
    _listado_motivos_autorizacion: state => state.listado_motivos_autorizacion,
    _listado_contratos: state => state.listado_contratos,
    _solicitud: state => state.solicitud,
    _solicitud_detalle: state => state.solicitud_detalle
  },
  actions: {
    paginarSolicitudes (params) {
      return _SolicitudContratoService.paginarSolicitudes().then(({ data }) => {
        this.paginado_solicitudes.rows = data.rows
        this.paginado_solicitudes.count = data.count
        return data
      })
    },
    buscarContratos (busqueda) {
      return _SolicitudContratoService.buscarContratos(busqueda).then(({ data }) => {
        this.listado_contratos = data
        return data
      })
    },
    buscarArticulos (params) {
      return _SolicitudContratoService.buscarMedicamentos(params).then(({ data }) => {
        this.listado_articulos = data
        return data
      })
    },
    obtenerMotivosAutorizacion () {
      this.listado_motivos_autorizacion = []
    },
    crearSolicitud (payload) {
      return _SolicitudContratoService.crearSolicitud(payload).then(({ data }) => {
        return data
      })
    },
    buscarDetalle (id) {
      return _SolicitudContratoService.buscarDetalle(id).then(({ data }) => {
        this.solicitud_detalle = data
        return data
      })
    },
    responderSolicitud (payload) {
      return _SolicitudContratoService.responderSolicitud(payload).then(({ data }) => {
        return data
      })
    },
    validarSolicitud (params) {
      return _SolicitudContratoService.validarSolicitidContratoPrecio(params).then(({ data }) => {
        return data
      })
    }
  }
})
